import Request from 'superagent';
import swal from 'sweetalert2';

let preset = process.env.NODE_ENV === 'production'? 'dvpys97w' : 'vloavxjm'
let cloud = process.env.NODE_ENV === 'production' ? 'dwtejx7pn' : 'marte1309';

const URL = `https://api.cloudinary.com/v1_1/${cloud}/image/upload`;

export const uploadFile = async (file, flashcard) => {
	if (flashcard) {
		preset = process.env.NODE_ENV === 'production'? 'a1abulea' : 'zluupuz3'
	}
	const result = await Request.post(URL)
		//.set('Content-Type', 'multipart/form-data')
		.field('upload_preset', preset)
  		.attach('file', file)
		.then(res => {
			if(res.body) {
				return res.body;
			} else {
				swal.fire('Oops!', 'No se obtuvo respuesta desde Cloudinary', 'error');
				return { error: true };
			}
		})
		.catch(error => {
			console.log(error);
			swal.fire('Oops!', error.message || 'Ocurrio un error al enviar archivo a Cloudinary', 'error');
			return { error: true };
		});

	return result;
}

