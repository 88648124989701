import React, { useEffect, useState } from 'react';
import Request from 'superagent';
import swal from 'sweetalert2';
import moment from 'moment';

import { db } from '../firebase';

const Notificaciones = () => {
	const [loading, setLoading] = useState(false);
	const [mensaje, setMensaje] = useState("");
	const [titulo, setTitulo] = useState("");
	const [tokens, setTokens] = useState([
		'dgbLS4t3QUuapRZK7y3_uv:APA91bG47JmickcwazJfud9BoULQXfHAF5HWe1UEsIwlvWKACqz5CVFKZneJDpwpiE0GGKRBNkruirR8xdCLlkU37Uwq7_k25WEOQAM0sOMbfkvE-UClUSWbvGyU4DhK_NQLEi23uL5I',
	]);
	const [docs, setDocs] = useState([]);

	useEffect(() => {
		getTokens();
		getNotificaciones();
	}, []);

	function getTokens() {
		db.collection('PushTokens').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				let document = doc.data();
				documents.push(document.token);
			});
			setTokens(documents);
		});
	}

	function getNotificaciones() {
		db.collection('PushNotificaciones').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	async function enviar() {
		setLoading(true);

		const server_key = "AAAAiuzUIK8:APA91bE5_fMLXUul5GYJb_S33ZSMwwQLBWP_Uq7tdNo5OjDggU0SUThORiSScIYkWl3UsgmMg9e9a0StVgdEpPgNAUBe5IgNXQm5nW_OQca3f0OpAx1Z0GIV5fmOgex7HGVEhOmzvFRS";

		const response = await Request.post('https://fcm.googleapis.com/fcm/send')
		.set("Content-Type", "application/json")
		.set("Authorization", `key=${server_key}`)
		.send({
			"registration_ids": tokens,
			"notification": {
				"title": titulo,
				"body": mensaje,
			},
			"data": {
				"url": "<url of media image>",
				"dl": "<deeplink action on tap of notification>"
			}
		})
		.then(res => {
			setLoading(false);

			return res.body;
		})
		.catch(error => {
			setLoading(false);

			return error;
		});

		console.log(response);

		if (response.multicast_id) {
			db.collection('PushNotificaciones').add({
				"titulo": titulo,
				"mensaje": mensaje,
				"fecha": moment().format('YYYY-MM-DD HH:mm'),
			});
			swal.fire('Enviada', 'Notificación enviada exitosamente', 'success');
		} else {
			swal.fire('Oops!', 'Ocurrio un error al enviar la notificación', 'error');
		}
	}

	return (
		<div className="notificaciones page">
			<div className="row">
				<input className="input" placeholder="Titulo" value={titulo} onChange={(event) => setTitulo(event.target.value)}/>
				<input className="input" placeholder="Mensaje..." value={mensaje} onChange={(event) => setMensaje(event.target.value)}/>
				{!loading ?
				<button className="btn" onClick={() => enviar()}>Enviar Notificación</button>
				:
				<button className="btn"><i className="fas fa-spinner fa-spin"></i></button>
				}
			</div>
			<div className="grid-container">
				<div className="grid-row-header">
					<h1>Titulo</h1>
					<h1>Mensaje</h1>
					<h1>Fecha</h1>
				</div>
				{docs.map(doc => <div className="grid-row" key={doc.id}>
					<h1>{doc.titulo}</h1>
					<h1>{doc.mensaje}</h1>
					<h1>{doc.fecha && moment(doc.fecha).format('DD/MM/YYYY HH:mm')}</h1>
				</div>)}
			</div>
		</div>
	)
}

export default Notificaciones
