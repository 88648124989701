import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { db } from '../firebase';
import { uploadFile } from '../cloudinary';

import Modal from '../components/Modal';
import DropImage from '../components/DropImage';

const EnarmSlider = () => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState({});
	const [docs, setDocs] = useState([]);

	useEffect(() => {
		getFlashCards();
	}, []);

	function getFlashCards() {
		db.collection('Enarm').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	function openModal(item) {
		setSelected(item);
		setShow(true);
	}

	function closeModal() {
		setSelected({});
		setShow(false);
	}

	function deleteCard(item) {
		Swal.fire({
			title: '¿Eliminar FlashCard?',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar'
		}).then(async (result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				await db.collection('Enarm')
					.doc(item.id)
					.delete()
					.then(() =>{
						Swal.fire('Eliminado!', '', 'success')
					}).catch(err => {
						console.log(err);
						Swal.fire("Oops!", "Ocurrio un error al eliminar", "error");
					});
			}
		})
	}

	return (
		<div className="enarmslider page">
			<div className="row">
				<button className="btn" onClick={() => openModal({nombre: ''})}>Agregar</button>
			</div>
			<div className="card-container">
				{docs.map(doc =>
					<div className="card" key={doc.id}>
						<div className="image-content" onClick={() => openModal(doc)}>
							<img src={doc.imagen || "noImage.jpeg"} alt={doc.nombre}/>
						</div>
						<div className="delete" onClick={() => deleteCard(doc)}>
							<i className="fas fa-trash"></i>
						</div>
						{/*<div className="title">
							<h1>{doc.nombre}</h1>
						</div>
						<div className="title">
							<h1>{doc.categoria}</h1>
						</div>
						<div className="description">
							<p>{doc.descripcion}</p>
						</div>*/}
					</div>
				)}
			</div>
			<Modal
				show={show}
				onClose={() => closeModal()}
				title={selected.nombre}>
				<CambiarImagen
					id={selected.id}
					imagen={selected.imagen}
					nombre={selected.nombre}
					link={selected.link}
					closeModal={() => closeModal()}
				/>
			</Modal>
		</div>
	)
}

const CambiarImagen = (props) => {
	const [info, setInfo] = useState(props);
	const [loading, setLoading] = useState(false);
	const [imagen, setImagen] = useState(props.imagen || "noImage.jpeg");

	useEffect(() => {
		setInfo(props);
		setImagen(props.imagen || "noImage.jpeg");
	}, [props])

	async function onChange(item, value) {
		setInfo({...info, [item]: value})
	}

	async function onSubmit(event) {
		event.preventDefault();
		setLoading(true);
		if(info.id) {
			await db.collection('Enarm')
				.doc(info.id)
				.update({nombre: info.nombre || '', link: info.link || '', imagen: imagen})
				.then(() =>{
					props.closeModal();
					Swal.fire("Actualizado", "", "success");
				}).catch(err => {
					console.log(err);
					Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
				});
		} else {
			await db.collection('Enarm')
				.add({nombre: info.nombre || '', link: info.link || '', imagen: imagen})
				.then(() =>{
					props.closeModal();
					Swal.fire("Agregado", "", "success");
				}).catch(err => {
					console.log(err);
					Swal.fire("Oops!", "Ocurrio un error al agregar", "error");
				});
		}
		setLoading(false);
	}

	async function uploadImage(data) {
		//Upload Cloudinary
		setLoading(true);

		const resultUpload = await uploadFile(data.file);

		if(resultUpload.secure_url || resultUpload.url) {
			setImagen(resultUpload.secure_url || resultUpload.url);
		}

		setLoading(false);
	}

	return (
		<div className="column full justify-center align-center categoria_modal">
			<DropImage
				setData={(data) => uploadImage(data)}
				setLoading={(load) => setLoading(load)}>
				<img src={`${imagen || 'noImage.jpeg'}`} alt={props.nombre || "Categoria"}/>
			</DropImage>
			<form className="column align-center" onSubmit={(event) => onSubmit(event)}>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Nombre: <input className="input" name="nombre" value={info.nombre || ""} onChange={(event) => onChange('nombre', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Link: <input className="input" name="link" value={info.link || ""} onChange={(event) => onChange('link', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>

				{loading ? 
					<div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<button className="btn">Guardar</button>
				}

				<div className="white-space-16"></div>
			</form>
		</div>
	)
}

export default EnarmSlider