import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { db, storage } from '../firebase';
import { uploadFile } from '../cloudinary';

import Modal from '../components/Modal';
import DropImage from '../components/DropImage';
import DropPdf from '../components/DropPdf';

const Resumenes = () => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState({});
	const [docs, setDocs] = useState([]);

	useEffect(() => {
		getNotificaciones();
	}, []);

	function getNotificaciones() {
		db.collection('PdfVenta').onSnapshot((querySnapshot) => {
			const documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setDocs(documents);
		});
	}

	function openModal(item) {
		setSelected(item);
		setShow(true);
	}

	function closeModal() {
		setSelected({});
		setShow(false);
	}

	function deleteResumen(item) {
		Swal.fire({
			title: '¿Eliminar Resumen?',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar'
		}).then(async (result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				await db.collection('PdfVenta')
					.doc(item.id)
					.delete()
					.then(() =>{
						Swal.fire('Eliminado!', '', 'success')
					}).catch(err => {
						console.log(err);
						Swal.fire("Oops!", "Ocurrio un error al eliminar", "error");
					});
			}
		})
	}

	return (
		<div className="resumenes page">
			<div className="row">
				<button className="btn" onClick={() => openModal({capitulo: '', precio: '0.0', url: ''})}>Agregar</button>
			</div>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>Categoria</th>
							<th>Capitulo</th>
							<th>Precio</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{docs.map(doc =>
						<tr key={doc.id}>
							<td onClick={() => openModal(doc)}>{doc.categoria}</td>
							<td onClick={() => openModal(doc)}>{doc.capitulo}</td>
							<td onClick={() => openModal(doc)}>$ {parseFloat(doc.precio || 0).toFixed(2)}</td>
							<td><i onClick={() => deleteResumen(doc)} className="fas fa-trash"></i></td>
						</tr>)}
					</tbody>
				</table>
			</div>
			<Modal
				show={show}
				onClose={() => closeModal()}
				title={selected.nombre}>
				<CambiarImagen
					id={selected.id}
					imagen={selected.imagen}
					url={selected.url}
					precio={selected.precio}
					categoria={selected.categoria}
					descripcion={selected.descripcion}
					capitulo={selected.capitulo}
					closeModal={() => closeModal()}
				/>
			</Modal>
		</div>
	)
}

const CambiarImagen = (props) => {
	const [info, setInfo] = useState(props);
	const [loading, setLoading] = useState(false);
	const [imagen, setImagen] = useState(props.imagen || "noImage.jpeg");
	const [categorias, setCategorias] = useState([]);
	const [pdf, setPdf] = useState(props.pdf || "");

	useEffect(() => {
		getCategorias();
	}, []);

	useEffect(() => {
		setInfo(props);
		setImagen(props.imagen || "noImage.jpeg");
	}, [props])

	function getCategorias() {
		db.collection('Categorias').orderBy("nombre", "asc").onSnapshot((querySnapshot) => {
			let documents = [];
			querySnapshot.forEach((doc) => {
				documents.push({...doc.data(), id: doc.id});
			});
			setCategorias(documents);
		});
	}

	async function onChange(item, value) {
		setInfo({...info, [item]: value})
	}

	async function onSubmit(event) {
		event.preventDefault();
		setLoading(true);
		if(info.id) {
			await db.collection('PdfVenta')
				.doc(info.id)
				.update({capitulo: info.capitulo, categoria: info.categoria, descripcion: info.descripcion, imagen: imagen, precio: info.precio, url: pdf})
				.then(() =>{
					props.closeModal();
					Swal.fire("Actualizado", "", "success");
				}).catch(err => {
					console.log(err);
					Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
				});
		} else {
			await db.collection('PdfVenta')
				.add({capitulo: info.capitulo, categoria: info.categoria, descripcion: info.descripcion, imagen: imagen, precio: info.precio, url: pdf})
				.then(() =>{
					props.closeModal();
					Swal.fire("Agregado", "", "success");
				}).catch(err => {
					console.log(err);
					Swal.fire("Oops!", "Ocurrio un error al agregar", "error");
				});
		}
		setLoading(false);
	}

	async function uploadImage(data) {
		//Upload Cloudinary
		setLoading(true);

		const resultUpload = await uploadFile(data.file);

		if(resultUpload.secure_url || resultUpload.url) {
			setImagen(resultUpload.secure_url || resultUpload.url);
		}

		setLoading(false);
	}

	async function uploadPdf(data, name) {
		//Upload Cloudinary
		setLoading(true);
		
		let storageRef = storage.ref();
		let pdfRef = storageRef.child(`Resumenes/${moment().format('YYYYMMDDHHmmss')}_${name}`);

		var metadata = {
			contentType: 'application/pdf',
		};

		await pdfRef.put(data, metadata).then(function(snapshot) {
			snapshot.ref.getDownloadURL().then(function(downloadURL) {
				//console.log('File available at', downloadURL);
				setPdf(downloadURL);
			});
		});

		/*const resultUpload = await uploadFile(data.file);

		if(resultUpload.secure_url || resultUpload.url) {
			setPdf(resultUpload.secure_url || resultUpload.url);
		}*/

		setLoading(false);
	}

	return (
		<div className="column full justify-center align-center categoria_modal">
			<DropImage
				setData={(data, name) => uploadImage(data)}
				setLoading={(load) => setLoading(load)}>
				<img src={`${imagen || 'noImage.jpeg'}`} alt={props.nombre || "Categoria"}/>
			</DropImage>
			<DropPdf
				setData={(data, name) => uploadPdf(data, name)}
				setLoading={(load) => setLoading(load)}
				pdf={info.url}>
			</DropPdf>
			<form className="column align-center" onSubmit={(event) => onSubmit(event)}>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Categoría:
					<select required className="input" name="categoria" value={info.categoria || ""} onChange={(event) => onChange('categoria', event.target.value)}>
						<option value="">Seleccionar Categoría</option>
						{categorias.map(c => <option key={c.id} value={c.nombre}>{c.nombre}</option>)}
					</select>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Capitulo: <input required className="input" name="capitulo" value={info.capitulo || ""} onChange={(event) => onChange('capitulo', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Precio: <input required className="input" name="precio" value={info.precio || ""} onChange={(event) => onChange('precio', event.target.value)}></input>
				</div>
				<div className="white-space-8"></div>
				<div className="row align-center">
					Descripción: <textarea required className="input" rows="4" name="descripcion" value={info.descripcion || ""} onChange={(event) => onChange('descripcion', event.target.value)}></textarea>
				</div>
				<div className="white-space-8"></div>

				{loading ? 
					<div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
					:
					<button className="btn">Guardar</button>
				}

				<div className="white-space-16"></div>
			</form>
		</div>
	)
}

export default Resumenes