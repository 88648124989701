import { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import { db } from '../firebase';

const Terminos = () => {
	const [doc, setDoc] = useState([]);
	const [text, setText] = useState("");

	useEffect(() => {
		getPrivacidad();
	}, []);

	function getPrivacidad() {
		db.collection('Privacidad').onSnapshot((querySnapshot) => {
			if(querySnapshot.docs[0]) {
				let document = querySnapshot.docs[0].data();
				document.id = querySnapshot.docs[0].id;
				setDoc(document);
			}
		});
	}

	useEffect(() => {
		if (doc.texto) {
			setText(doc.texto);
		}
	}, [doc]);

	async function guardar() {
		console.log(doc, text);
		await db.collection('Privacidad')
			.doc(doc.id)
			.update({texto: text})
			.then(() =>{
				Swal.fire("Actualizado", "", "success");
			}).catch(err => {
				console.log(err);
				Swal.fire("Oops!", "Ocurrio un error al actualizar", "error");
			});
	}

	async function reset() {
		setText(doc.texto);
	}

	return (
		<div className="privacidad">
			<div className="container column">
				<div className="row">
					<button className="btn" onClick={() => reset()}>Reset</button>
					<button className="btn" onClick={() => guardar()}>Guardar</button>
				</div>
				<div className="text-container">
					<CKEditor
						editor	= { ClassicEditor }
						data	= {text}
						onChange={ ( event, editor ) => {
							const data = editor.getData();
							setText(data);
						} }/>
				</div>
			</div>
		</div>
	)
}

export default Terminos;