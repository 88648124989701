import React, { useEffect } from 'react';

const DropPdf = (props) => {

	useEffect(() => {
		const fileText		= document.getElementById('pdf-text');
		fileText.innerHTML 	= `<p>Arrastra tu PDF aquí para cargarlo<p>`;
	}, [props.pdf])

	useEffect(() => {
		const fileDrag		= document.getElementById('pdf-drag');
		const fileSelect	= document.getElementById('pdf-upload');
		const fileText		= document.getElementById('pdf-text');

		if (fileDrag) {
			fileDrag.addEventListener('dragover', fileDragHover, false);
			fileDrag.addEventListener('dragleave', fileDragHover, false);
			fileDrag.addEventListener('drop', fileSelectHandler, false);
		}
	
		if (fileSelect) {
			fileSelect.addEventListener('change', fileSelectHandler, false);
		}

		function fileDragHover(e) {
			e.preventDefault();
			fileDrag.className = (e.type === 'dragover' ? 'hover' : '');
		}
	
		function fileSelectHandler(e) {
			fileDragHover(e);
	
			const files = e.target.files || e.dataTransfer.files;
			const file = files[0];
			const isGood = (/\.(?=pdf)/gi).test(file.name);
	
			if (!isGood) {
				fileText.innerHTML = 'Tipo de archivo no válido';
				return;
			}
	
			if (fileText && file) {
				fileText.innerHTML = `<p>${file.name}<p>`;

				const reader = new FileReader();
	
				reader.readAsArrayBuffer(file);
	
				reader.onload = () => {
					console.log(file.name)
					if(props.setData) {
						props.setData(reader.result, file.name);
						document.getElementById("pdf-upload").value = "";
					}
				}
			}
		}
	}, []);

	return (
		<div className="input-drop-pdf">
			<label htmlFor="pdf-upload" id="pdf-drag" className="">
				<div className="title-drop-pdf">
					<i className="fas fa-upload"></i>
					<p id="pdf-text">Arrastra tu PDF aquí para cargarlo</p>
					<div className="watch-pdf">
						{props.pdf && <i className="fas fa-file-pdf" onClick={() => window.open(props.pdf, "newtab")}></i>}
					</div>
				</div>
			</label>
			<input className="hidden" id="pdf-upload" type="file" name="pdfUpload" accept=".pdf" />
		</div>
	)
}

export default DropPdf;