import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import './firebase';
import './styles/style.scss';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

import Login from './pages/login';
import Home from './pages/Home';
import Anuncios from './pages/Anuncios';
import Categorias from './pages/Categorias';
import PdfDisponibles from './pages/PdfDisponibles';
import PrecioSuscripciones from './pages/PrecioSuscripciones';
import Suscripciones from './pages/Suscripciones';
import Usuarios from './pages/Usuarios';
import Preguntas from './pages/Preguntas';
import ListaPreguntas from './pages/Preguntas/ListaPreguntas';
import BuscarPreguntas from './pages/Preguntas/BuscarPregunta';
import Notificaciones from './pages/Notificaciones';
import FlashCards from './pages/FlashCards';
import Resumenes from './pages/Resumenes';
import EnarmSlider from './pages/EnarmSlider';
import Privacidad from './pages/Privacidad';

const Private = (props) => {
	const { component: Component, ...rest } = props;

	let validate = localStorage.getItem('usermail') !== null ? true : false;

	return (
		<Route
			{...rest}
			render={(props) =>
				validate ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)}
		/>
	)
}

function App() {

	let validate = localStorage.getItem("usermail") !== null ? true : false;

	if (validate) {
		return (
			<BrowserRouter>
				<Header />
				<Sidebar>
					<Private path="/home" exact component={Home}/>
					<Private path="/categorias" exact component={Categorias}/>
					<Private path="/usuarios" exact component={Usuarios}/>
					<Private path="/suscripciones" exact component={Suscripciones}/>
					<Private path="/preciosuscripciones" exact component={PrecioSuscripciones}/>
					<Private path="/pdfdisponibles" exact component={PdfDisponibles}/>
					<Private path="/anuncios" exact component={Anuncios}/>
					<Private path="/preguntas" exact component={Preguntas}/>
					<Private path="/preguntas/:id/:categoria" exact component={ListaPreguntas}/>
					<Private path="/preguntas/:id/:categoria/:pregunta" exact component={BuscarPreguntas}/>
					<Private path="/notificaciones" excat component={Notificaciones}/>
					<Private path="/flashcards" excat component={FlashCards}/>
					<Private path="/resumenes" excat component={Resumenes}/>
					<Private path="/enarmslider" excat component={EnarmSlider}/>
					<Private path="/privacidad" excat component={Privacidad}/>
				</Sidebar>
			</BrowserRouter>
		);
	} else {
		return (
			<BrowserRouter>
				<Route path="/login" exact component={Login}/>
				<Route path="/" exact component={Login}/>
				<Redirect to={{ pathname: "/login" }} />
			</BrowserRouter>
		);
	}
}

export default App;
