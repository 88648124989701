import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/firebase-storage';
   
const config = process.env.NODE_ENV === 'production'? {
	apiKey: "AIzaSyDhSsgV5cj93iD0_AJW8BxilBbIPC-h_xo",
    authDomain: "drenarm-fd0c9.firebaseapp.com",
    projectId: "drenarm-fd0c9",
    storageBucket: "drenarm-fd0c9.appspot.com",
    messagingSenderId: "596678811823",
    appId: "1:596678811823:web:4646822ab3ae5536b8f45a",
    measurementId: "G-K2V7TNC29W",
	bucket: "gs://drenarm-fd0c9.appspot.com"
} : {
	apiKey: "AIzaSyCV7LZJtaLZUqumFelTcD220NdRfiYeDGI",
	authDomain: "drenarm-22c83.firebaseapp.com",
	projectId: "drenarm-22c83",
	storageBucket: "drenarm-22c83.appspot.com",
	messagingSenderId: "188960198753",
	appId: "1:188960198753:web:77e9e65a47b61b3d619a20" ,
	bucket: "gs://drenarm-22c83.appspot.com"
};
//initialize firebase  
const fb = firebase.initializeApp(config, 'drenarm');

export const db = fb.firestore();
export const storage = fb.storage();
export const auth = fb.auth();

